
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref, watch, toRefs } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "timetable-accueil",
  components: {},
  props: {
    classRoomId: String,
  },
  setup(props) {
    const { t } = useI18n();
    const { classRoomId } = toRefs(props);

    const activeDay = "wednesday";
    const daysList = ["monday", "tuesday", "wednesday", "thursday", "friday"];

    const timetable = ref({});

    daysList.forEach((day) => {
      timetable.value[day] = [];
    });
    watch(classRoomId, (currentValue) => {
      getShedule(currentValue);
    });
    const getShedule = (id) => {
      ApiService.post("/schedules/filter", {
        query: {
          classeRoom: id,
          status: { $ne: "inactive" },
        },
        aggregation: [
          {
            $lookup: {
              from: "teachers",
              localField: "teacher",
              foreignField: "_id",
              as: "teacher",
            },
          },
          {
            $lookup: {
              from: "subjects",
              localField: "subject",
              foreignField: "_id",
              as: "subject",
            },
          },
          {
            $lookup: {
              from: "modules",
              localField: "module",
              foreignField: "_id",
              as: "module",
            },
          },
          {
            $lookup: {
              from: "modulespecifics",
              localField: "moduleSpecific",
              foreignField: "_id",
              as: "moduleSpecific",
            },
          },
          {
            $lookup: {
              from: "rooms",
              localField: "room",
              foreignField: "_id",
              as: "room",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classeRoom",
              foreignField: "_id",
              as: "classeRoom",
            },
          },
          {
            $project: {
              _id: 1,
              teacher: {
                $let: {
                  vars: {
                    teacherVar: {
                      $arrayElemAt: ["$teacher", 0],
                    },
                  },
                  in: {
                    _id: "$$teacherVar._id",
                    employee: "$$teacherVar.employee",
                  },
                },
              },
              classeRoom: {
                $let: {
                  vars: {
                    classVar: {
                      $arrayElemAt: ["$classeRoom", 0],
                    },
                  },
                  in: {
                    name: "$$classVar.name",
                    _id: "$$classVar._id",
                  },
                },
              },
              room: {
                $let: {
                  vars: {
                    roomVar: {
                      $arrayElemAt: ["$room", 0],
                    },
                  },
                  in: {
                    name: "$$roomVar.name",
                    _id: "$$roomVar._id",
                  },
                },
              },
              time: 1,
              day: 1,
              subject: {
                $let: {
                  vars: {
                    subjectVar: {
                      $arrayElemAt: ["$subject", 0],
                    },
                  },
                  in: {
                    name: "$$subjectVar.name",
                    _id: "$$subjectVar._id",
                    status: "$$subjectVar.status",
                  },
                },
              },
              module: {
                $let: {
                  vars: {
                    moduleVar: {
                      $arrayElemAt: ["$module", 0],
                    },
                  },
                  in: {
                    name: "$$moduleVar.name",
                    _id: "$$moduleVar._id",
                    status: "$$moduleVar.status",
                  },
                },
              },
              moduleSpecific: {
                $let: {
                  vars: {
                    moduleSpecificVar: {
                      $arrayElemAt: ["$moduleSpecific", 0],
                    },
                  },
                  in: {
                    name: "$$moduleSpecificVar.name",
                    _id: "$$moduleSpecificVar._id",
                    status: "$$moduleSpecificVar.status",
                  },
                },
              },
              ends: 1,
              duration: 1,
              type: 1,
              name: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          data.forEach((l) => {
            if (!l.type) l.type = "S";
            if (
              l.day < 5 &&
              (l.type != "S" || l.subject.status !== "inactive") &&
              (l.type != "MP" || l.module.status !== "inactive") &&
              (l.type != "MS" || l.moduleSpecific.status !== "inactive")
            ) {
              const schedName = l.name
                ? l.name
                : l.type == "MP"
                ? l.module.name
                : l.type == "MS"
                ? l.moduleSpecific.name
                : l.subject.name;
              timetable.value[daysList[l.day]].push({
                lesson: 0,
                subject: schedName,
                time: `${l.time}-${l.ends}`,
                cabinet: l.room.name,
                start: l.time,
                end: l.ends,
              });
            }
          });

          daysList.forEach((day) => {
            // sort times
            timetable.value[day] = timetable.value[day].sort(function (a, b) {
              return (
                Number(a.start.split(":")[0]) - Number(b.start.split(":")[0])
              );
            });

            // eslint-disable-next-line
            let lastEnd: any = undefined;
            for (const l of timetable.value[day]) {
              if (lastEnd) {
                if (lastEnd.end != l.start) {
                  lastEnd.break = `${lastEnd.end}-${l.start}`;
                }
              }
              lastEnd = l;
            }
          });
        })
        .catch((e) => console.log(e));
    };

    return { t, timetable, activeDay };
  },
});
