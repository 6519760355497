import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-10" }
const _hoisted_2 = { class: "card-body d-flex align-items-center py-8" }
const _hoisted_3 = { class: "d-flex h-80px w-80px flex-shrink-0 flex-center position-relative" }
const _hoisted_4 = { class: "svg-icon svg-icon-primary position-absolute opacity-15" }
const _hoisted_5 = { class: "svg-icon svg-icon-3x svg-icon-primary position-absolute" }
const _hoisted_6 = { class: "ms-6" }
const _hoisted_7 = { class: "list-unstyled text-gray-600 fw-bold fs-6 p-0 m-0" }
const _hoisted_8 = { class: "fw-bolder text-primary my-6" }
const _hoisted_9 = { class: "row g-5 g-xl-10 mb-5 mb-xl-10" }
const _hoisted_10 = { class: "col-xl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Timetable = _resolveComponent("Timetable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, {
              src: "media/icons/duotune/abstract/abs051.svg",
              class: "h-80px w-80px"
            })
          ]),
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen005.svg" })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t("schedule.informations")), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t("report.update")) + " " + _toDisplayString(_ctx.updateAt || "--"), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_Timetable, { classRoomId: _ctx.classRoomId }, null, 8, ["classRoomId"])
      ])
    ])
  ], 64))
}