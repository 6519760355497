
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import Timetable from "@/components/Timetable.vue";

export default defineComponent({
  name: "Emplois",
  components: { Timetable },
  setup(props) {
    const store = useStore();

    const { t } = useI18n();
    onMounted(() => {
      setCurrentPageTitle("dashboard.schedule");
    });
    const classRoomId = ref("");
    const studentInfo = ref({
      classRoom: {
        emplois: {},
        updatedAt: "--",
      },
    });
    const updateAt = ref("--");
    ApiService.post(
      //this.studentId
      `/students/filter`,
      {
        query: {
          _id: window.localStorage.getItem("activeStudent"),
        },
        aggregation: [
          {
            $set: {
              classRoom: {
                $toObjectId: `$schoolarYearsHistory.${window.localStorage.getItem(
                  "activeSchoolarYear"
                )}`,
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: { $arrayElemAt: ["$classRoom", 0] },
            },
          },
        ],
      }
    )
      .then(({ data }) => {
        studentInfo.value = data[0];
        classRoomId.value = data[0].classRoom._id;
        let monthName = new Date(studentInfo.value.classRoom.updatedAt);
        updateAt.value = monthName.toLocaleString("fr-FR");
      })
      .catch((e) => {
        //router.push("/auth/404");
      });
    return {
      updateAt,
      studentInfo,
      classRoomId,
      t,
      store,
    };
  },
});
